import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MyButton from "../components/MyButton";
//import { redirect } from "react-router-dom";
//import { generateRandomString } from "../commons/utilities";
import { getLMSLink_API } from "../api/api";
import MySpinner from "../components/MySpinner";
//import CourseCard from "../components/CourseCard";
import Card from "../components/card/Card";
import { useAuthenticationContext } from "../context/AuthenticationContext";
import { useCoursesContext } from "../context/CoursesContext";
import classes from "./page.module.css";
import multipleCardClasses from "../components/multipleCard.module.css";

function LMS() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(["classroom"]);
  const { authenticationStatus } = useAuthenticationContext();
  const { generalCourses, userCourses } = useCoursesContext();
  const [getLMSLinkResponse, setGetLMSLinkResponse] = useState(null);
  const [isLMSLinkAvailable, setIsLMSLinkAvailable] = useState(false);
  const [lmsLink, setLmsLink] = useState(null);
  const [isLMSLinkError, setIsLMSLinkError] = useState(false);
  const [lmsLinkErrorMessage, setLMSLinkErrorMessage] = useState(null);

  //const [getLMSCoursesResponse, setGetLMSCoursesResponse] = useState(null);
  //const [isWaitingForLMSCourses, setIsWaitingForLMSCourses] = useState(false);
  //const [lmsCourses, setLMSCourses] = useState(null);

  const [loggedIn, setLoggedIn] = useState(authenticationStatus.authenticated);
  const [isBusy, setIsBusy] = useState(false);

  const token = process.env.REACT_APP_MOODLE_DOWNLOAD_IMAGES_TOKEN;

  const clientID = "testclient";

  //get lms link when component mounts
  useEffect(() => {
    console.log("Get LMS Link");
    getLMSLink();
  }, []);

  //reload component when authenticationStatus changes
  useEffect(() => {
    console.log(
      `reload page whenever authenticationStatus changes and/or courses`
    );
    setLoggedIn(authenticationStatus.authenticated);
  }, [authenticationStatus, generalCourses, userCourses]);

  useEffect(() => {
    if (getLMSLinkResponse) {
      setIsBusy(false);
      console.log(`getLMSLinkResponse: ${getLMSLinkResponse}`);
      if (getLMSLinkResponse.success && getLMSLinkResponse.url) {
        console.log(`***** LMS URL: ${getLMSLinkResponse.url}`);
        setLmsLink(getLMSLinkResponse.url);
        setIsLMSLinkAvailable(true);
        setIsLMSLinkError(false);
        setLMSLinkErrorMessage(null);

        //console.log(`Redirecting user to: ${getLMSLinkResponse.url}`);
        //window.location.href = getLMSLinkResponse.url;
      } else {
        setIsLMSLinkError(true);
        if (!getLMSLinkResponse.success) {
          console.log(
            `Failed to get LMS Link for user, error message: ${getLMSLinkResponse.errorMessage}`
          );
          setLMSLinkErrorMessage(
            `Failed to get LMS Link for user, error message: ${getLMSLinkResponse.errorMessage}`
          );
        } else {
          console.log(`LMS URL is NULL.`);
          setLMSLinkErrorMessage(`LMS URL is NULL.`);
        }
      }
      setGetLMSLinkResponse(null);
    }
  }, [getLMSLinkResponse]);

  const getLMSLink = () => {
    setGetLMSLinkResponse(null);
    setIsLMSLinkAvailable(false);
    setIsLMSLinkError(false);
    getLMSLink_API(authenticationStatus.username, setGetLMSLinkResponse);
    setIsBusy(true);

    return;
  };

  return (
    <div className={classes.main_div}>
      <div className={classes.body_div}>
        <div className={classes.body_main_container}>
          {loggedIn && (
            <div className={classes.body_sub_container}>
              {/*<span className={classes.sub_container_title}>
                This is personal lms space.
              </span>*/}

              {isLMSLinkAvailable && lmsLink ? (
                <MyButton
                  variant={"primary"}
                  title={t("Go to classroom")}
                  onClickHandler={() => {
                    console.log(`******** Redirecting user to: ${lmsLink}`);
                    setIsBusy(true);
                    //window.location.href = lmsLink;
                    window.open(lmsLink, "_blank");
                    setIsBusy(false);
                  }}
                  disabled={isBusy}
                />
              ) : (
                <div>{t("generating classroom link")}</div>
              )}
              {isLMSLinkError && lmsLinkErrorMessage && (
                <div>{lmsLinkErrorMessage}</div>
              )}
            </div>
          )}
          {loggedIn && (
            <div className={classes.body_sub_container}>
              <span className={classes.sub_container_title}>
                {t("your current courses")}
              </span>
              <div className={multipleCardClasses.multiple_card_container}>
                {userCourses && userCourses.length > 0 ? (
                  <div
                    className={
                      multipleCardClasses.multiple_card_container_wrapper
                    }
                  >
                    {userCourses.map((course) => (
                      /*<CourseCard
                        course={course}
                        variant="user"
                        key={course.id}
                      />*/
                      <Card
                        title={course.fullname}
                        imageSrc={`${course.image}?token=${token}`}
                        imageAlt={`${course.fullname} Image`}
                        text={`You have completed (${course.progress}%) of this course. Keep it up!`}
                        variant={"no_url"}
                        url={`/lms/${course.shortname}`}
                      />
                    ))}
                  </div>
                ) : (
                  <div>{t("no courses")}</div>
                )}
              </div>
            </div>
          )}
          {loggedIn && (
            <div className={classes.body_sub_container}>
              <span className={classes.sub_container_title}>
                {t("courses of interest to you")}
              </span>
              <p>{t("under construction")}</p>
            </div>
          )}
          {!loggedIn && (
            <div className={classes.body_sub_container}>
              <span className={classes.sub_container_title}>
                {t("login or create an account")}
              </span>
              <p>
                <span
                  className={classes.importantTextPointer}
                  onClick={() => navigate("/login")}
                >
                  {t("login here")}
                </span>{" "}
                <span>{t("or")}</span>{" "}
                <span
                  className={classes.importantTextPointer}
                  onClick={() => navigate("/signup")}
                >
                  {t("create an account")}
                </span>
                <span>{" "} {t("and start enjoying EdIsCool's courses")}</span>
              </p>
            </div>
          )}
          <div className={classes.body_sub_container}>
            <span className={classes.sub_container_title}>
              {t("courses offered by EdIsCool")}
            </span>
            <div className={multipleCardClasses.multiple_card_container}>
              {generalCourses && generalCourses.length > 0 ? (
                <div
                  className={
                    multipleCardClasses.multiple_card_container_wrapper
                  }
                >
                  {generalCourses.map((course) => (
                    /*<CourseCard course={course} key={course.id} />*/
                    <Card
                      title={course.fullname}
                      imageSrc={`${course.image}?token=${token}`}
                      imageAlt={`${course.fullname} Image`}
                      text={course.summary}
                      variant={"with_url"}
                      url={`/lms/${course.shortname}`}
                    />
                  ))}
                </div>
              ) : (
                <div>{t("no courses offered")}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isBusy && <MySpinner message={"Wait..."} />}
    </div>
  );
}

export default LMS;
