import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Pagination, EffectFade, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/bundle";
import { useAppearanceContext } from "../../context/AppearanceContext";

import { images } from "./SwiperData";
import heroClasses from "./heroSection.module.css";

const HeroSection = () => {
  const { t, i18n } = useTranslation(["home"]);
  const { isMobile, isSmallerScreen } = useAppearanceContext();

  /*useEffect(() => {
    console.log(`number of images: ${images.length}`);
  }, [images]);*/

  return (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 4000,
        disableOnInteraction: false,
      }}
      effect={"fade"}
      loop={true}
      modules={[Pagination, EffectFade, Autoplay]}
      className={heroClasses.mySwiper}
    >
      {images.map((image) => (
        <SwiperSlide key={image.index} className={heroClasses.mySwiperSlide}>
          <div
            className={
              image.index % 2 === 0
                ? heroClasses.overlayTextRight
                : heroClasses.overlayTextLeft
            }
            dir={i18n.dir()}
          >
            <p
              className={
                isSmallerScreen ? heroClasses.title_mobile : heroClasses.title
              }
              dir={i18n.dir()}
            >
              {i18n.language === "ar" ? image.title_ar : image.title}
            </p>
            <p
              className={
                isSmallerScreen ? heroClasses.text_mobile : heroClasses.text
              }
              dir={i18n.dir()}
            >
              {i18n.language === "ar" ? image.text_ar : image.text}
            </p>
          </div>
          <img
            className={
              isSmallerScreen ? heroClasses.img_mobile : heroClasses.img_desktop
            }
            key={image.index}
            src={image.image}
            alt={`Soorah index: ${image.index}`}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default HeroSection;
